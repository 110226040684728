import 'jspdf-autotable'
import jsPDF from 'jspdf'
import { orderBy } from 'lodash'

export default {
  methods: {
    // DOWNLOAD PDF
    downloadPDF(zip = false) {
      console.log('downloading pdf')
      console.log(this.plan)
      return new Promise((resolve, reject) => {
        console.log('starting promise')
        try {
          const doc = new jsPDF()
          let hideEnergy
          let hideMealTags
          let hideNutriTags

          let isOnRecipeEbook = this.isOnRecipeEbook || false

          if (isOnRecipeEbook) {
            hideEnergy = this.promoData?.hideEnergy || false
            hideMealTags = this.promoData?.hideMealTags || false
            hideNutriTags = this.promoData?.hideNutriTags || false
          }
          else if (this.plan) {
            hideEnergy = this.plan.hideEnergy
            hideMealTags = this.plan.hideMealTags
            hideNutriTags = this.plan.hideNutriTags
          } 
          else {
            hideEnergy = (this.$route.query['hide-energy'] == 'true')
            hideMealTags = (this.$route.query['hide-mealtags'] == 'true')
            hideNutriTags = (this.$route.query['hide-nutritags'] == 'true')
          }

          console.log({ hideEnergy })
          console.log({ hideMealTags })
          console.log({ hideNutriTags })

          // var hideEnergy = this.plan.hideEnergy

          let logoImg = new Image()
          let user = this.user || {}
          // let mealzeeImg = new Image

          // mealzeeImg.src = 'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fpowered_by.png?alt=media&token=53f85656-97eb-49ba-9fe1-3389861b9b6b'

          logoImg.src = user.customLogo ? user.customLogoUrl : 'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fmealzee_pdf.png?alt=media&token=b078269d-186b-4249-b3c1-b15b50ad041e'

          var rgb = null

          if (user.brandColor) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.user.brandColor)
            rgb = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
          }

          var primaryColor = rgb || [151, 200, 31]
          var warningColor = [251, 140, 0]

          console.log('Onload image')
          console.log(logoImg)
          logoImg.onload = () => {
            console.log('Starting onload image')
            if (user?.isStudent) {
              doc.autoTable({
                head: [
                  [{ content: '*Student Sample Only', styles: { fontStyle: 'bold', fontSize: 12, textColor: [255, 0, 0] } }]
                ],
                theme: 'plain'
              })
            }

            if (this.$store.getters['subscriptions/isOnLow']) {
              doc.autoTable({
                head: [
                  [{ content: '' }, { content: 'Powered by Mealzee' }]
                ],
                theme: 'plain',
                body: [[''], ['']],
                columnStyles: {
                  0: { cellWidth: 140 },
                  1: { cellWidth: 40 },
                },
                didDrawCell: function (data) {
                  if (data.row.index == 0 && data.column.index == 0 && data.cell.section == 'head') {
                    doc.addImage(logoImg, 'png', (data.cell.x + 1), data.cell.y, logoImg.width / (logoImg.height / 23), 23)
                  }

                  // if (data.row.index == 0 && data.column.index == 1 && data.cell.section == 'head') {
                  //   doc.addImage(mealzeeImg, 'png', (data.cell.x + 1),  data.cell.y, logoImg.width / (logoImg.height/25), 23)
                  // }
                },
              })
            } else {
              doc.autoTable({
                head: [
                  [{ content: '' }]
                ],
                theme: 'plain',
                didDrawCell: function (data) {
                  console.log(data)
                  if (data.column.index == 0 && data.cell.section == 'head') {
                    doc.addImage(logoImg, 'JPEG', (data.cell.x + 2), (data.cell.y), logoImg.width / (logoImg.height / 20), 20, undefined, 'FAST')
                  }
                },
              })
            }

            doc.autoTable({
              head: [
                [{ content: this.recipe.name, styles: { fontStyle: 'bold', fontSize: 17, cellPadding: { top: 10 } } }]
              ],
              body: user.role == 'member' ? [[{ content: `Created by ${this.$store.getters['members/getUser'](this.recipe.user)}`, styles: { fontSize: 8 } }]] : [],
              theme: 'plain'
            })

            let ingredientsData = []

            if(this.$route.name == 'PromoEbookPage' || this.$route.name == 'MealplanEbook') {
              orderBy(this.recipe.ingredients, 'order', 'asc').forEach(ing => {
                if (this.recipe.lock_serve) {
                  ingredientsData.push([ing.food, `${this.addFixedDecimals(ing.quantity)} ${ing.portion}`])
                } else {
                  ingredientsData.push([
                    ing.food,
                    `${this.addFixedDecimals((ing.quantity * (((this.recipe?.customServe ? this.recipe?.customServe : this.serve)|| this.recipe.numberOfServes) / this.getServes(this.recipe?.rawMealTime))))} ${ing.portion}`
                  ])
                }
              })
            } else {
              orderBy(this.recipe.ingredients, 'order', 'asc').forEach(ing => {
                if (this.recipe.lock_serve) {
                  ingredientsData.push([ing.food, `${this.addFixedDecimals(ing.quantity)} ${ing.portion}`])
                } else {
                  ingredientsData.push([
                    ing.food,
                    `${this.addFixedDecimals((ing.quantity * (((this.recipe?.customServe ? this.recipe?.customServe : this.serve)|| this.recipe.numberOfServes) / this.recipe.numberOfServes)))} ${ing.portion}`
                  ])
                }
              })
            }

            let img = new Image
            img.src = this.image || this.recipe?.mediumPhotoUrl || this.recipe?.OriginalPhotoUrl;

            img.onload = () => {
              doc.autoTable({
                theme: 'grid',
                headStyles: { fillColor: primaryColor },
                head: [
                  [
                    { content: 'Ingredients', colSpan: 2, styles: { fontStyle: 'bold' } },
                    { content: '', rowSpan: this.recipe.ingredients.length + 1, styles: { fillColor: [0, 0, 0, 0] } }
                  ]
                ],
                columnStyles: {
                  0: { cellWidth: 60 },
                  1: { cellWidth: 60 },
                  2: { cellWidth: 60 },
                },
                didDrawCell: function (data) {
                  if (data.column.index == 2 && data.cell.section == 'head') {
                    if (!img.src.includes('placeholder.svg')) {
                      doc.addImage(img, 'JPEG', (data.cell.x + 1), data.cell.y, 58, img.height / (img.width / 58), 'FAST')
                    }
                  }
                },
                styles: { fontSize: 8 },
                body: ingredientsData,
              })

              var methods = null

              if (this.recipe.methods && this.recipe.methods.length) {
                methods = this.recipe.methods.map((m, i) => {
                  return `${i + 1}. ${m}`
                })
              }


              console.log({ recipe: this.recipe })
              console.log({ serves: this.serves })

              console.log(this.$route)

              let body = []

              if(this.$route.name == 'PromoEbookPage' || this.$route.name == 'MealplanEbook') {
                body = [
                  [{ content: this.recipe.lock_serve ? "WARNING: Original serve quantities shown only due to ingredients that don't divide well" : '', styles: { textColor: warningColor, fontStyle: 'bold' } }],
                  [{ content: `Makes ${this.recipe.lock_serve ? this.recipe.numberOfServes : ((this.recipe?.customServe ? this.recipe?.customServe : this.getServes(this.recipe?.rawMealTime)) || this.recipe.numberOfServes)} Serves (${Math.round(this.recipe?.servingValues.weight)}g per serve)` }],
                  [{ content: 'Meal Time', styles: { fontStyle: 'bold', textColor: primaryColor } }],
                  [{ content: this.recipe.mealTime.join(', ') }],
                  [{ content: 'Description', styles: { fontStyle: 'bold', textColor: primaryColor } }],
                  [{ content: this.recipe.description || 'none', styles: { cellWidth: 120 } }],
                  [{ content: 'Methods', styles: { fontStyle: 'bold', textColor: primaryColor } }],
                  [methods ? methods.join("\r\n") : 'none'],
                ]
              } else {

                body = [
                  [{ content: this.recipe.lock_serve ? "WARNING: Original serve quantities shown only due to ingredients that don't divide well" : '', styles: { textColor: warningColor, fontStyle: 'bold' } }],
                  [{ content: `Makes ${this.recipe.lock_serve ? this.recipe.numberOfServes : ((this.recipe?.customServe ? this.recipe?.customServe : this.serves) || this.recipe.numberOfServes)} Serves (${Math.round(this.recipe?.servingValues.weight)}g per serve)` }],
                  [{ content: 'Meal Time', styles: { fontStyle: 'bold', textColor: primaryColor } }],
                  [{ content: this.recipe.mealTime.join(', ') }],
                  [{ content: 'Description', styles: { fontStyle: 'bold', textColor: primaryColor } }],
                  [{ content: this.recipe.description || 'none', styles: { cellWidth: 120 } }],
                  [{ content: 'Methods', styles: { fontStyle: 'bold', textColor: primaryColor } }],
                  [methods ? methods.join("\r\n") : 'none'],
                ]

              }

              if (!hideMealTags) {
                body.push(
                  [{ content: 'Meal Tags', styles: { fontStyle: 'bold', textColor: primaryColor } }]
                )

                body.push(
                  [this.recipe?.tags ? this.recipe.tags.map(tag => { if (this.$store.getters['tags/checkIfUnpublish'](tag)) return this.$store.getters['tags/getName'](tag) }).filter((val) => val).join(", ") : 'None']
                )
              }

              if (!hideNutriTags) {
                body.push(
                  [{ content: 'Nutrition Tags', styles: { fontStyle: 'bold', textColor: primaryColor } }]
                )

                body.push(
                  [this.recipe.nutritionTags ? this.recipe.nutritionTags.map(tag => { if (this.$store.getters['nutritiontags/checkIfUnpublish'](tag)) return this.$store.getters['nutritiontags/tagData'](tag).name }).filter(val => val).join(", ") : 'None'],
                )
              }

              if(this.recipe?.toggleCompany) {
                  body.push([{ content: `Ready made meal by ${this.recipe?.companyName}`}], [{ content: `Buy now at ${this.recipe?.companyLink}`, styles: { textColor: primaryColor }}])
              }


              doc.autoTable({
                theme: 'plain',
                styles: { fontSize: 8, },
                body: body,
                bodyStyles: { lineWidth: 0 }
              })

              if (!hideEnergy) {
                let summaryData = [
                  ['', 'Quantity per Serving', 'Quantity per 100g'],
                  ['Weight (g)', `${this.$options.filters.numberFormat(this.recipe?.servingValues.weight)}g`, '100.00g'],
                ]

                let values = [
                  {
                    text: 'Energy',
                    measurement: user.energyUnit == 'cal' ? 'Cal' : 'kJ',
                    value: user.energyUnit == 'cal' ? this.$options.filters.numberFormat(Math.round(this.$options.filters.kjToCal(this.recipe?.servingValues.energy))) : this.$options.filters.numberFormat(Math.round(this.recipe?.servingValues.energy)),
                  },
                  {
                    text: 'Protein',
                    measurement: 'g',
                    value: this.$options.filters.numberFormat(Math.round(this.recipe?.servingValues.protein)),
                  },
                  {
                    text: 'Fat',
                    measurement: 'g',
                    value: this.$options.filters.numberFormat(Math.round(this.recipe?.servingValues.fat)),
                  },
                  {
                    text: 'Carbohydrate',
                    measurement: 'g',
                    value: this.$options.filters.numberFormat(Math.round(this.recipe?.servingValues.carbohydrate)),
                  },
                  {
                    text: 'Sugar',
                    measurement: 'g',
                    value: this.$options.filters.numberFormat(Math.round(this.recipe?.servingValues.sugar)),
                  },
                  {
                    text: 'Fibre',
                    measurement: 'g',
                    value: this.$options.filters.numberFormat(Math.round(this.recipe?.servingValues.fibre)),
                  },
                  {
                    text: 'Sodium',
                    measurement: 'mg',
                    value: this.$options.filters.numberFormat(Math.round(this.recipe?.servingValues.sodium)),
                  },
                ]

                var hundredGramValues = {
                  Energy: user.energyUnit == 'cal' ? this.$options.filters.numberFormat(Math.round(this.$options.filters.kjToCal(this.recipe?.servingValuesPerHundredGram.energy))) : this.$options.filters.numberFormat(Math.round(this.recipe?.servingValuesPerHundredGram.energy)),
                  Protein: this.recipe?.servingValuesPerHundredGram.protein,
                  Fat: this.recipe?.servingValuesPerHundredGram.fat,
                  Carbohydrate: this.recipe?.servingValuesPerHundredGram.carbohydrate,
                  Sugar: this.recipe?.servingValuesPerHundredGram.sugar,
                  Fibre: this.recipe?.servingValuesPerHundredGram.fibre,
                  Sodium: this.recipe?.servingValuesPerHundredGram.sodium,
                }

                values.forEach(n => {
                  summaryData.push([
                    `${n.text} (${n.measurement})`,
                    `${n.value}${n.measurement}`,
                    `${hundredGramValues[n.text]}${n.measurement}`
                  ])
                })

                doc.autoTable({
                  theme: 'grid',
                  headStyles: { fillColor: primaryColor },
                  styles: { fontSize: 8 },
                  head: [
                    [{ content: 'Analysis Summary', colSpan: 3, styles: { fontStyle: 'bold' } }]
                  ],
                  body: summaryData
                })
              }

              if (!zip) doc.save(`${this.recipe.name}.pdf`)
              resolve(doc)
            }

          }
        } catch (error) {
          console.log(error)
          reject()
        }

      })
    }
  },
}